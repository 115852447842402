import request from '@/utils/request'


// 查询国家攻略反馈记录列表
export function listFeedback(query) {
  return request({
    url: '/strategy/feedback/list',
    method: 'get',
    params: query
  })
}

// 查询国家攻略反馈记录分页
export function pageFeedback(query) {
  return request({
    url: '/strategy/feedback/page',
    method: 'get',
    params: query
  })
}

// 查询国家攻略反馈记录详细
export function getFeedback(data) {
  return request({
    url: '/strategy/feedback/detail',
    method: 'get',
    params: data
  })
}

// 新增国家攻略反馈记录
export function addFeedback(data) {
  return request({
    url: '/strategy/feedback/add',
    method: 'post',
    data: data
  })
}

// 修改国家攻略反馈记录
export function updateFeedback(data) {
  return request({
    url: '/strategy/feedback/edit',
    method: 'post',
    data: data
  })
}

// 删除国家攻略反馈记录
export function delFeedback(data) {
  return request({
    url: '/strategy/feedback/delete',
    method: 'post',
    data: data
  })
}
